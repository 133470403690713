.education {
    padding: 20px;
  }
  
  .education h3 {
    color: #fff;
  }

  .education p {
    color: #fff;
  }

  .education-item {
    margin-bottom: 20px;
    margin: 20px; 
  }
  
  .education-degree {
    font-style: italic;
  }
  
  .education-date {
    color: #777;
  }