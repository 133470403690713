.skills {
  padding: 20px;
  color: #ffffff;
}

.skill-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-category h3 {
  margin-bottom: 10px;
  color: #fff;
}

.skill-category p {
  color: #fff;
}

.skill-category ul {
  color: #fff;
}

.skill-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-circle-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 10px;
  border-radius: 50%;
  background-color: #f1f1f1;
  font-size: 24px;
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.custom-circle-item:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #007bff;
}

.soft-skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.soft-skills h3 {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.features-big .soft-skills {
  width: 100%;
}

.soft-skills-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.soft-skills-list li {
  margin: 5px 0;
  font-size: 18px;
}

.tooltip-text {
  visibility: hidden;
  width: 140px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /*Ubicación*/
  left: 50%;
  margin-left: -70px; /* Ancho del tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px; /* Tamaño de fuente*/
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* flecha en la parte superior del tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent; /* Cambia el color según el fondo del tooltip */
}

.custom-circle-item:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
