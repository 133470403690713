.about {
    padding: 20px;
  }
  
  .about h2 {
    margin-bottom: 15px;
  }
  
  .about p {
    font-size: 18px;
    line-height: 1.6;
    color: #fff;
  }
.about-me-text{
  color: #fff;
}
.profile-img {
    width: 300px; /* Tamaño Imagen */
    height: 300px;
    border-radius: 50%; /* Hace que sea circular */
    object-fit: cover; /* Asegura que la imagen cubra todo el espacio circular */
  }

  .text-gradient {
    background: -webkit-linear-gradient(315deg, #1e30f3 0%, #e21e80 100%);
    -webkit-background-clip: text;;
    -webkit-text-fill-color: transparent
  }

  /* Estilos básicos para todos los botones */
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Estilos para el botón primario */
.btn-primary {
  color: #fff;
  background-color: #1e30f3 ;
  border: 1px solid rgba(30, 48, 243, 1);
}

